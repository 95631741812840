import { notification } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { createPackingSlip, downloadFile } from 'services/intake';
import actions from './actions';

export function* callCreatePackingSlip({ payload }) {
  try {
    const response = yield call(createPackingSlip, payload);
    yield put({
      type: actions.CREATE_PACKING_SUCCESS,
      payload: {
        url: response.data['packing-slip-url'],
        fedexImg: response.data.fedex_label_png,
      },
    });
    yield call(downloadFile, {
      link: response.data['packing-slip-url'],
      name: response.data['packing-slip-filename'],
      contentType: 'application/pdf',
    });
  } catch (error) {
    yield put({ type: actions.CREATE_PACKING_FAILURE });

    notification.error({ message: error.message });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_PACKING_REQUEST, callCreatePackingSlip)]);
}
